import classNames from "@calcom/lib/classNames";

export default function Logo({
  small,
  icon,
  inline = true,
  className,
}: {
  small?: boolean;
  icon?: boolean;
  inline?: boolean;
  className?: string;
}) {
  return (
    <h1 className={classNames("logo", inline && "inline", className)}>
      <strong>
        {/* {icon ? (
          <img className="mx-auto w-9 dark:invert" alt="Plushvie" title="Plushvie" src="https://www.plushvie.in/image/admin/plushvie_logo_transparent.png" />
        ) : ( */}
          <img
            className={classNames(small ? "w-auto" : "w-auto", "dark:invert")}
            alt="Plushvie"
            title="Plushvie"
            src="https://console.plushvie.in/image/admin/plushvie_logo_transparent.png"
          />
        {/* )} */}
      </strong>
    </h1>
  );
}
